export const tenant = window['gon']?.tenant;
export const tenantName = tenant?.name;
export const tenantId = tenant?.id;
export const tenantFlavor = tenant?.flavor;
export const tenantFlavorFull = tenantFlavor === 'full';
export const tenantPortalName = tenant?.portal_name || 'Customer Portal';
export const tenantLogoUrl = tenant?.logo_url;
export const tenantWebsite = tenant?.website;
export const tenantPhone = tenant?.phone_number;
export const tenantDomain = tenant?.domain;
export const tenantLeadProgramBiddingEnabled =
  tenant?.lead_program_bidding === 'enabled';

export const tenantPrimaryColor = tenant?.primary_color || '#1155CC';
export const tenantBigLogoUrl =
  tenant?.logo_url ||
  'https://energypal.com/brand/EnergyPal-Logo-FullName-blue.png';
export const tenantSmallLogoUrl =
  tenant?.small_logo_url ||
  'https://energypal.com/brand/EnergyPal-Logo-E-blue.png';
export const tenantDarkLogoUrl =
  tenant?.dark_logo_url ||
  'https://energypal.com/brand/EnergyPal-Logo-FullName-white.png';
